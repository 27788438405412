@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    url("../assets/fonts/open-sans-v28-latin-300.woff2") format("woff2"),
    url("../assets/fonts/open-sans-v28-latin-300.woff") format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url("../assets/fonts/open-sans-v28-latin-regular.woff2") format("woff2"),
    url("../assets/fonts/open-sans-v28-latin-regular.woff") format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    url("../assets/fonts/open-sans-v28-latin-700.woff2") format("woff2"),
    url("../assets/fonts/open-sans-v28-latin-700.woff") format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src:
    url("../assets/fonts/open-sans-v28-latin-italic.woff2") format("woff2"),
    url("../assets/fonts/open-sans-v28-latin-italic.woff") format("woff");
}
