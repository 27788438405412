@use "fonts";
@use "variables" as *;

html,
body {
  font-family: "Open Sans", sans-serif;
  background-color: var(--md-sys-color-surface);
  color: var(--md-sys-color-on-surface);
}

html {
  scroll-padding-top: 5rem;
}

svg {
  fill: currentcolor;
}

.no-decoration {
  text-decoration: none;
  color: inherit;
}

a {
  color: var(--md-sys-color-primary);
}

a:visited {
  color: var(--md-sys-color-tertiary);
}

.navbar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 4rem;
  z-index: 999;
  background-color: var(--md-sys-color-surface-container-low);
  color: var(--md-sys-color-on-surface);
  box-shadow: 0 0 5px 2px rgba(0 0 0 / 10%);
  padding: 0 max(0rem, (100vw - 48rem) / 2);
}

.navbar-title {
  margin: 0 0 0 1rem;
  font-size: 1.25rem;
  line-height: 4rem;
  font-weight: bold;

  a {
    text-decoration: none;
    color: inherit;
  }
}

.navbar-right {
  float: right;
  margin: 0 0.5rem 0 0;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.navbar-right>* {
  margin-right: 0.25rem;
}

.button-secondary,
.button-secondary:visited {
  background-color: var(--md-sys-color-secondary-container);
  color: var(--md-sys-color-on-secondary-container);
  display: inline-block;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 0.5rem;
  text-decoration: none;
  border-radius: 0.3125rem;
  border: 0;
}

.button-secondary:hover {
  filter: brightness(0.9);
}

.button-secondary:active {
  filter: brightness(0.9);
  position: relative;
  top: 0.0625rem;
}

.main,
.footer {
  margin: 5rem auto 0;
  width: 48rem;
}

.footer {
  background-color: var(--md-sys-color-surface);
  color: var(--md-sys-color-on-surface);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  min-height: 6rem;
  gap: 0.5rem;
  padding: 0 0 2rem;
}

.footer-details {
  color: var(--md-sys-color-on-surface-variant);
  font-size: 0.75rem;
  font-style: italic;
  padding: 0 1rem;
}

.social-icons {
  color: inherit;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: 0.5rem;
  height: 1.5rem;

  a {
    color: inherit;

    svg {
      height: 100%;
      width: auto;
    }
  }
}

.data-section {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  align-content: flex-start;
  gap: 0.5rem;
}

.data-selector {
  height: 2.5rem;
}

.data-column {
  width: 20rem;
  flex: 1 1 auto;
  background-color: var(--md-sys-color-surface-container-high);
  color: var(--md-sys-color-on-surface);
  font-size: 0.875rem;
  border-radius: 0.375rem;
  overflow: hidden;
  min-height: 80rem;
}

.data-column-header {
  height: 13rem;
  margin: 0 0 0.5rem;
  padding: 0.25rem;
}

.data-column-title {
  display: block;
  text-align: center;
  margin: 0.5rem 0 1rem;
  font-weight: 700;
}

.column-info {
  margin: 0.5rem 0 0;
}

.column-warning {
  display: none;
  margin: 0.5rem 0 0;
  color: var(--md-sys-color-error);
  font-weight: 700;
  text-decoration: underline;
}

.column-count {
  margin: 0.5rem 0 0;
  display: block;
}

.data-column-footer {
  margin: 0 0 0.5rem;
  padding: 0.25rem;
  min-height: 12rem;
}

.data-column-sources>a {
  display: block;
  margin: 1rem 0 0 0.5rem;
}

.data-column-status {
  display: block;
  font-weight: 700;
  position: absolute;
  width: 12rem;
  margin: 0.125rem 0 0 0.125rem;
}

.data-column-rows {
  height: 40rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  align-content: flex-start;

  /* Consider removing or reducing the gap to maximize visualization accuracy. */
  gap: 0.125rem;
  font-size: 0.875rem;
  line-height: 0.875rem;
}

.data-column-skipped {
  padding: 0 0 0 1rem;
}

.data-column-skipped>li {
  padding: 0 0 0.625rem;
}

.intro-section,
.global-controls-section,
.data-intro-section,
.faq-section {
  margin: 1rem 0.5rem;
}

.reference-selector,
.company-selector {
  width: 80%;
}

.data-row {
  flex: 0 1 auto;

  // color: $visualization-text;
  color: #fff;
  background-color: #dfffab;
  overflow: hidden;
}

@media (width < 48rem) {
  .main,
  .footer {
    width: 100%;
  }

  .data-column {
    font-size: 0.75rem;
  }

  .data-column-status {
    width: 6rem;
  }

  .data-column-rows {
    font-size: 0.75rem;
    line-height: 0.63rem;
    height: 36rem;
  }

  .data-column-header {
    height: 14.75rem;
  }
}

.hisp-male-data-row {
  background-color: $hisp-male-bg;
}

.hisp-female-data-row {
  background-color: $hisp-female-bg;
}

.wh-male-data-row {
  background-color: $wh-male-bg;
}

.blk-male-data-row {
  background-color: $blk-male-bg;
}

.nhopi-male-data-row {
  background-color: $nhopi-male-bg;
}

.asian-male-data-row {
  background-color: $asian-male-bg;
}

.aian-male-data-row {
  background-color: $aian-male-bg;
}

.tomr-male-data-row {
  background-color: $tomr-male-bg;
}

.wh-female-data-row {
  background-color: $wh-female-bg;
}

.blk-female-data-row {
  background-color: $blk-female-bg;
}

.nhopi-female-data-row {
  background-color: $nhopi-female-bg;
}

.asian-female-data-row {
  background-color: $asian-female-bg;
}

.aian-female-data-row {
  background-color: $aian-female-bg;
}

.tomr-female-data-row {
  background-color: $tomr-female-bg;
}

.other-groups-data-row {
  background-color: $other-groups-bg;
}

.faq-entry {
  margin: 1.5rem 1rem 1rem;
}

.faq-entry-question {
  font-weight: 700;
}

.faq-entry-answer {
  padding: 0.5rem;
}

.data-chart-section {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.data-chart-container {
  flex: 0 1 auto;
  height: 25rem;
}

.heavyweight {
  font-weight: 900;
}

.right {
  text-align: right;
}