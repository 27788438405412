/* Visualization color palette. */
$hisp-male-bg: #240038;
$hisp-female-bg: #165459;
$wh-male-bg: #7D0226;
$blk-male-bg: #3E3E23;
$nhopi-male-bg: #193006;
$asian-male-bg: #9b1e95;
$aian-male-bg: #391cbb;
$tomr-male-bg: #8b2929;
$wh-female-bg: #A8450C;
$blk-female-bg: #01579B;
$nhopi-female-bg: #082b3d;
$asian-female-bg: #350808;
$aian-female-bg: #502b3f;
$tomr-female-bg: #00824c;
$other-groups-bg: #555;
$visualization-text: #212121;

:root {
  --md-sys-color-primary: rgb(67 94 145);
  --md-sys-color-surface-tint: rgb(67 94 145);
  --md-sys-color-on-primary: rgb(255 255 255);
  --md-sys-color-primary-container: rgb(216 226 255);
  --md-sys-color-on-primary-container: rgb(42 70 120);
  --md-sys-color-secondary: rgb(86 94 113);
  --md-sys-color-on-secondary: rgb(255 255 255);
  --md-sys-color-secondary-container: rgb(219 226 249);
  --md-sys-color-on-secondary-container: rgb(63 71 89);
  --md-sys-color-tertiary: rgb(113 85 116);
  --md-sys-color-on-tertiary: rgb(255 255 255);
  --md-sys-color-tertiary-container: rgb(251 215 252);
  --md-sys-color-on-tertiary-container: rgb(88 62 91);
  --md-sys-color-error: rgb(186 26 26);
  --md-sys-color-on-error: rgb(255 255 255);
  --md-sys-color-error-container: rgb(255 218 214);
  --md-sys-color-on-error-container: rgb(147 0 10);
  --md-sys-color-background: rgb(249 249 255);
  --md-sys-color-on-background: rgb(26 27 32);
  --md-sys-color-surface: rgb(249 249 255);
  --md-sys-color-on-surface: rgb(26 27 32);
  --md-sys-color-surface-variant: rgb(225 226 236);
  --md-sys-color-on-surface-variant: rgb(68 71 79);
  --md-sys-color-outline: rgb(116 119 127);
  --md-sys-color-outline-variant: rgb(196 198 208);
  --md-sys-color-shadow: rgb(0 0 0);
  --md-sys-color-scrim: rgb(0 0 0);
  --md-sys-color-inverse-surface: rgb(47 48 54);
  --md-sys-color-inverse-on-surface: rgb(240 240 247);
  --md-sys-color-inverse-primary: rgb(173 199 255);
  --md-sys-color-primary-fixed: rgb(216 226 255);
  --md-sys-color-on-primary-fixed: rgb(0 26 65);
  --md-sys-color-primary-fixed-dim: rgb(173 199 255);
  --md-sys-color-on-primary-fixed-variant: rgb(42 70 120);
  --md-sys-color-secondary-fixed: rgb(219 226 249);
  --md-sys-color-on-secondary-fixed: rgb(19 27 44);
  --md-sys-color-secondary-fixed-dim: rgb(190 198 220);
  --md-sys-color-on-secondary-fixed-variant: rgb(63 71 89);
  --md-sys-color-tertiary-fixed: rgb(251 215 252);
  --md-sys-color-on-tertiary-fixed: rgb(41 19 45);
  --md-sys-color-tertiary-fixed-dim: rgb(222 188 223);
  --md-sys-color-on-tertiary-fixed-variant: rgb(88 62 91);
  --md-sys-color-surface-dim: rgb(217 217 224);
  --md-sys-color-surface-bright: rgb(249 249 255);
  --md-sys-color-surface-container-lowest: rgb(255 255 255);
  --md-sys-color-surface-container-low: rgb(243 243 250);
  --md-sys-color-surface-container: rgb(237 237 244);
  --md-sys-color-surface-container-high: rgb(232 231 238);
  --md-sys-color-surface-container-highest: rgb(226 226 233);
}

@media (prefers-color-scheme: dark) {
  :root {
  --md-sys-color-primary: rgb(173 199 255);
  --md-sys-color-surface-tint: rgb(173 199 255);
  --md-sys-color-on-primary: rgb(15 47 96);
  --md-sys-color-primary-container: rgb(42 70 120);
  --md-sys-color-on-primary-container: rgb(216 226 255);
  --md-sys-color-secondary: rgb(190 198 220);
  --md-sys-color-on-secondary: rgb(40 48 65);
  --md-sys-color-secondary-container: rgb(63 71 89);
  --md-sys-color-on-secondary-container: rgb(219 226 249);
  --md-sys-color-tertiary: rgb(222 188 223);
  --md-sys-color-on-tertiary: rgb(64 40 67);
  --md-sys-color-tertiary-container: rgb(88 62 91);
  --md-sys-color-on-tertiary-container: rgb(251 215 252);
  --md-sys-color-error: rgb(255 180 171);
  --md-sys-color-on-error: rgb(105 0 5);
  --md-sys-color-error-container: rgb(147 0 10);
  --md-sys-color-on-error-container: rgb(255 218 214);
  --md-sys-color-background: rgb(17 19 24);
  --md-sys-color-on-background: rgb(226 226 233);
  --md-sys-color-surface: rgb(17 19 24);
  --md-sys-color-on-surface: rgb(226 226 233);
  --md-sys-color-surface-variant: rgb(68 71 79);
  --md-sys-color-on-surface-variant: rgb(196 198 208);
  --md-sys-color-outline: rgb(142 144 153);
  --md-sys-color-outline-variant: rgb(68 71 79);
  --md-sys-color-shadow: rgb(0 0 0);
  --md-sys-color-scrim: rgb(0 0 0);
  --md-sys-color-inverse-surface: rgb(226 226 233);
  --md-sys-color-inverse-on-surface: rgb(47 48 54);
  --md-sys-color-inverse-primary: rgb(67 94 145);
  --md-sys-color-primary-fixed: rgb(216 226 255);
  --md-sys-color-on-primary-fixed: rgb(0 26 65);
  --md-sys-color-primary-fixed-dim: rgb(173 199 255);
  --md-sys-color-on-primary-fixed-variant: rgb(42 70 120);
  --md-sys-color-secondary-fixed: rgb(219 226 249);
  --md-sys-color-on-secondary-fixed: rgb(19 27 44);
  --md-sys-color-secondary-fixed-dim: rgb(190 198 220);
  --md-sys-color-on-secondary-fixed-variant: rgb(63 71 89);
  --md-sys-color-tertiary-fixed: rgb(251 215 252);
  --md-sys-color-on-tertiary-fixed: rgb(41 19 45);
  --md-sys-color-tertiary-fixed-dim: rgb(222 188 223);
  --md-sys-color-on-tertiary-fixed-variant: rgb(88 62 91);
  --md-sys-color-surface-dim: rgb(17 19 24);
  --md-sys-color-surface-bright: rgb(55 57 62);
  --md-sys-color-surface-container-lowest: rgb(12 14 19);
  --md-sys-color-surface-container-low: rgb(26 27 32);
  --md-sys-color-surface-container: rgb(30 32 37);
  --md-sys-color-surface-container-high: rgb(40 42 47);
  --md-sys-color-surface-container-highest: rgb(51 53 58);
  }
}
